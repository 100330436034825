import React from "react"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title, Quote } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import { FigureContainer, Figure } from "../components/Figure"
import praxisHome from "../images/belt/praxisHome.jpg"
import portraitClelia from "../images/belt/portraitClelia.jpg"
import praxis from "../images/praxis.png"
import spiralIcon from "../images/spiralIcon.png"

const IndexPage = () => (
  <>
    <SEO
      title="Home"
      keywords={[
        "Homöopathie",
        "Alternativmedizin",
        "Praxis",
        "Clelia Sasselli",
        "Zürich",
        "Ganzheitlich",
        "Natürlich",
        "Globuli",
        "Behandlung für die ganze Familie",
        "Neugeborene",
        "Kinder",
        "Schwangerschaft",
        "Ganz sich selbst sein",
      ]}
      description="Natürlich und ganzheitlich Wohlbefinden und Gesundheit erlangen, bei chronischen und akuten Krankheiten. Praxis für klassische Homöopathie in Zürich, für die ganze Familie, Kinder und Erwachsene."
    />
    <Belt leftImage={praxisHome} rightImage={portraitClelia} />
    <Container>
      <Quote cite="C. S. Lewis">
        Du kannst nicht zurückgehen und den Anfang verändern.
        <wbr /> Aber du kannst starten wo du bist und das Ende verändern.
      </Quote>
      <FigureContainer>
        <Figure src={spiralIcon} alt="Spirale" />
      </FigureContainer>
      <StaticBlockContainer cols={2}>
        <StaticBlock>
          <Title>
            AKTUELL-CORONA
            <wbr />
            &shy;
          </Title>
          <p>
            Ab dem 29.04.2020 empfange ich Sie gerne wieder persöhnlich in
            meiner Praxis. Dabei beachte ich die Hygiene-Vorschriften vom BAG.
          </p>
          <p>
            Um eine Erkrankung vorzubeugen, ist es wichtig Körper und Geist zu
            stärken. Unter Aktuell-Corona finden Sie wertvolle Tips dazu.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>ANGEBOT</Title>
          <p>
            Die Homöopathie ist eine eigenständige Heilmethode und gehört zur
            Alternativmedizin. Sie kann wirkungsvoll für ein breites Spektrum
            von akuten sowie chronischen Krankheiten eingesetzt werden.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>FÜR DIE GANZE FAMILIE</Title>
          <p>
            Als natürliche Behandlungsmethode eignet sich die Homöopathie für
            die ganze Familie, von Jung bis Alt. Die Globuli sind gut
            verträglich und können auch bei Neugeborenen, Kinder und während der
            Schwangerschaft eingenommen werden.
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>GANZHEITLICH &amp; NATÜRLICH</Title>
          <p>
            Bei der Behandlung steht nicht nur die Krankheit, sondern der Mensch
            als Ganzes im Vordergrund. Die homöopathischen Globuli werden aus
            natürlichen Rohstoffen hergestellt und wirken sanft und dauerhaft.
          </p>
        </StaticBlock>
      </StaticBlockContainer>
    </Container>
  </>
)

export default IndexPage
